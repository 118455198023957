// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications-container {
    width: 80%;
    /* max-width: 600px; */
    margin: 50px 17% ;
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .notifications-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .notifications-list {
    list-style: none;
    padding: 10px;
    display: flex;
    margin: 0;
    gap: 30px;
  }
  
  .notification-item {
    padding: 25px;
    margin: 20px 0px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
   
    width: 100%;
    text-align: left;
  }
  
  .notification-item:hover {
    background-color: #f1f1f1;
  }

  
  .notification-message {
    font-size: 14px;
    margin: 0 0 10px 0;
    color: #555;
  }
  
  .notification-time {
    font-size: 12px;
    color: #999;
  }
 
@media (max-width: 900px){
    .notifications-container {
      width: 90%;
      margin: 20px auto;
      padding: 20px;
    }
    .notifications-list{
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/Styles/notification.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,sBAAsB;IACtB,iBAAiB;IACjB,aAAa;IACb,+BAA+B;IAC/B,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,SAAS;IACT,SAAS;EACX;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;IAClB,sCAAsC;IACtC,sCAAsC;;IAEtC,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;;EAGA;IACE,eAAe;IACf,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;AAEF;IACI;MACE,UAAU;MACV,iBAAiB;MACjB,aAAa;IACf;IACA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".notifications-container {\n    width: 80%;\n    /* max-width: 600px; */\n    margin: 50px 17% ;\n    padding: 20px;\n    /* background-color: #f9f9f9; */\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .notifications-title {\n    font-size: 24px;\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .notifications-list {\n    list-style: none;\n    padding: 10px;\n    display: flex;\n    margin: 0;\n    gap: 30px;\n  }\n  \n  .notification-item {\n    padding: 25px;\n    margin: 20px 0px;\n    background-color: #fff;\n    border-radius: 5px;\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);\n    transition: background-color 0.3s ease;\n   \n    width: 100%;\n    text-align: left;\n  }\n  \n  .notification-item:hover {\n    background-color: #f1f1f1;\n  }\n\n  \n  .notification-message {\n    font-size: 14px;\n    margin: 0 0 10px 0;\n    color: #555;\n  }\n  \n  .notification-time {\n    font-size: 12px;\n    color: #999;\n  }\n \n@media (max-width: 900px){\n    .notifications-container {\n      width: 90%;\n      margin: 20px auto;\n      padding: 20px;\n    }\n    .notifications-list{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
