import React from 'react'

const Footer = () => {
  return (
    <footer className="footer-section">
         <p>Copyright &copy; 2024, BQTech</p>
    </footer>
  )
}

export default Footer