// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-popup {
  position: fixed;
  top: 18%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.confirmation-popup p {
  margin-bottom: 10px;
}

.confirmation-popup .buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.confirmation-popup button {
  padding: 8px 20px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

@media (max-width: 500px){
  .confirmation-popup p{
      margin: 10px;
      text-align: center;
  }
  .confirmation-popup{
      width: 70%;
      padding: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Styles/signout.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,2CAA2C;EAC3C,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE;MACI,YAAY;MACZ,kBAAkB;EACtB;EACA;MACI,UAAU;MACV,aAAa;EACjB;AACF","sourcesContent":[".confirmation-popup {\n  position: fixed;\n  top: 18%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  padding: 40px;\n  border-radius: 5px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  z-index: 9999;\n}\n\n.confirmation-popup p {\n  margin-bottom: 10px;\n}\n\n.confirmation-popup .buttons {\n  display: flex;\n  justify-content: center;\n  gap: 20px;\n}\n\n.confirmation-popup button {\n  padding: 8px 20px;\n  font-size: 14px;\n  border: none;\n  cursor: pointer;\n  border-radius: 5px;\n  transition: background-color 0.3s;\n}\n\n@media (max-width: 500px){\n  .confirmation-popup p{\n      margin: 10px;\n      text-align: center;\n  }\n  .confirmation-popup{\n      width: 70%;\n      padding: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
