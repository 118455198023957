// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    color:#1e4a88 ;

    font-size: 22px;
    margin: 10px ;
}
.subtitle{
    font-size: 18px;
    padding: 7px 0;    
    color: #d4af37;
}

.button {
    background-color: #8B6A00;
    color: #FFFFFF;
    border: 1px solid #8B6A00;
    padding: 10px 20px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    width: 60%;
}

.para {
    color: #a67193;
    font-size: 16px;
}

.dashboard {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
.card {
    border: 1px solid #E0E0E0;
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
}

.card-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.card-title {
    color: #3A3A3A;
    font-weight: bold;
}

.card-description {
    color: #5A5A5A;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/container.css"],"names":[],"mappings":"AAAA;IACI,cAAc;;IAEd,eAAe;IACf,aAAa;AACjB;AACA;IACI,eAAe;IACf,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,iCAAiC;IACjC,UAAU;AACd;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,aAAa;EACf;AACF;IACI,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,iCAAiC;AACrC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".title {\n    color:#1e4a88 ;\n\n    font-size: 22px;\n    margin: 10px ;\n}\n.subtitle{\n    font-size: 18px;\n    padding: 7px 0;    \n    color: #d4af37;\n}\n\n.button {\n    background-color: #8B6A00;\n    color: #FFFFFF;\n    border: 1px solid #8B6A00;\n    padding: 10px 20px;\n    cursor: pointer;\n    font-family: 'Roboto', sans-serif;\n    width: 60%;\n}\n\n.para {\n    color: #a67193;\n    font-size: 16px;\n}\n\n.dashboard {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px;\n    padding: 20px;\n  }\n.card {\n    border: 1px solid #E0E0E0;\n    padding: 20px;\n    margin: 10px;\n    border-radius: 5px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    font-family: 'Roboto', sans-serif;\n}\n\n.card-image {\n    width: 100%;\n    height: auto;\n    border-radius: 5px;\n}\n\n.card-title {\n    color: #3A3A3A;\n    font-weight: bold;\n}\n\n.card-description {\n    color: #5A5A5A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
