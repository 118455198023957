// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxes-container {
  margin-left: 16%;
  /* margin-top: 5%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  padding: 30px;
  flex-wrap: wrap; 
}
.boxes-container .title{
  text-align: center;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  
}
.box {
  border: 1px solid #ddd; 
  border-radius: 8px;
  padding: 30px 40px;
  cursor: pointer;
  text-align: center;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 375px; 
  height: 250px;
 background: #f2f2f2;
}

.box:hover {
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.box-title {
  font-size: 1.25rem;
  margin-bottom: 5px;
  color: #514797;
}

.box-subtitle {
  font-size: 0.875rem;
  color: #888;
}

.boxes-container  p {
  margin-bottom: 15px; 
}

.boxes-container  p span {
  color: #a67193;
  font-weight: bold;
}

@media (max-width: 900px) {
  .boxes-container {
    margin: 10px;
    gap: 20px;
    padding: 10px;
    flex-direction: column;
  }
  .box {
    width: 90%; 
    /* height: 300px; */
    padding: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Styles/boxes.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,aAAa;EACb,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;;AAE3B;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,wCAAwC;EACxC,qBAAqB;EACrB,cAAc;EACd,kDAAkD;EAClD,YAAY;EACZ,aAAa;CACd,mBAAmB;AACpB;;AAEA;EACE,yBAAyB;EACzB,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;IACZ,SAAS;IACT,aAAa;IACb,sBAAsB;EACxB;EACA;IACE,UAAU;IACV,mBAAmB;IACnB,aAAa;EACf;AACF","sourcesContent":[".boxes-container {\n  margin-left: 16%;\n  /* margin-top: 5%; */\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 40px;\n  padding: 30px;\n  flex-wrap: wrap; \n}\n.boxes-container .title{\n  text-align: center;\n  margin: 0 0 20px 0;\n  text-transform: uppercase;\n  \n}\n.box {\n  border: 1px solid #ddd; \n  border-radius: 8px;\n  padding: 30px 40px;\n  cursor: pointer;\n  text-align: center;\n  background: #fff;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); \n  text-decoration: none;\n  color: inherit;\n  transition: background-color 0.3s, box-shadow 0.3s;\n  width: 375px; \n  height: 250px;\n background: #f2f2f2;\n}\n\n.box:hover {\n  background-color: #f9f9f9;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); \n}\n\n.box-title {\n  font-size: 1.25rem;\n  margin-bottom: 5px;\n  color: #514797;\n}\n\n.box-subtitle {\n  font-size: 0.875rem;\n  color: #888;\n}\n\n.boxes-container  p {\n  margin-bottom: 15px; \n}\n\n.boxes-container  p span {\n  color: #a67193;\n  font-weight: bold;\n}\n\n@media (max-width: 900px) {\n  .boxes-container {\n    margin: 10px;\n    gap: 20px;\n    padding: 10px;\n    flex-direction: column;\n  }\n  .box {\n    width: 90%; \n    /* height: 300px; */\n    padding: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
